import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import VueI18n from "vue-i18n";
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  lazy: false,
  locale: "ar",
  messages: {
    en: {
      _about: "About",
      _timings: "Timings",
      _locations: "Locations",
      _gallery: "Gallery",
      _offers: "Offers",
      _brandName: "Temave Fabrics and Curtains",
      _addText1: "Big Sales",
      _addText2: "Free Installation",
      _addText3: "Free Measurements",
      _addText4: "Special Discount on Fabric Customization ",
      _curtainsCollection: "Curtains Collection",
      _curtainsOffers: "Curtains Offers",
      _saturday: "Saturday",
      _sunday: "Sunday",
      _monday: "Monday",
      _closed: "Closed",
      _workTime: "9:00 AM - 11:00 PM",
      _clickForMore: "Read more",

      _locationsHeader:
        "Visit our stores to explore our curtain collection in person",
      _aboutP1: `Welcome to
      <b class='accent-txt'>Temave for Curtains and Fabric </b> Store!
      With over 21 years of experience in the market, we have established
      ourselves as a leading provider of high-quality curtains. Since
      2002, we have been dedicated to offering the best fabric materials,
      competitive prices, and exceptional after-sale service to our valued
      customers.`,
      _aboutP2: `At our three <b>Curtains and Fabric</b> branches located in
      <b class="accent-txt bold"> Nasr City, Maadi, and 6th of October City</b>
      we take pride in our wide selection of curtains that cater to
      various styles and preferences. Whether you're looking for blackout
      curtains, sheer curtains, or kids curtains, we have the
      perfect options to enhance the ambiance of your home.`,
      _aboutP3: `As part of our commitment to customer convenience, we offer free
      measurements to ensure the perfect fit for your windows. We also
      provide free delivery services, bringing your selected curtains
      right to your doorstep. Furthermore, our skilled professionals offer
      free installation, ensuring a hassle-free experience from start to
      finish.`,

      //Addresses
      _addresses: {
        branch1: {
          title: "NASR CITY BRANCH",
          address:
            "43 Mostafa El-Nahaas St, Nasr City, Cairo Governorate, Egypt",
        },
        branch2: {
          title: "AL MAADI BRANCH",
          address: "30 ElNasr St, Nasr City, Cairo Governorate, Egypt.",
        },
        branch3: {
          title: "6th of OCTIBER CITY BRANCH",
          address:
            "Al Batea- Moazi Llmehwar Al Markazi St, First 6th of October, Giza Governorate, Egypt.",
        },
      },

      // Offers
      _offersList: {
        offer1: {
          title: "Save 30% on Your Total Bill",
          details:
            " For a limited time, enjoy a generous discount of 30% off your total bill. Beautify your home with our premium curtains while saving big. use the code: NEWSITE_30",
        },
        offer2: {
          title: "Free Installation Included: ",
          details:
            "Take advantage of our offer and receive complimentary professional installation with your curtain purchase. Sit back and relax while our experts handle the installation for you.",
        },
        offer3: {
          title: "Enjoy Free Delivery:",
          details:
            "Get your curtains delivered straight to your doorstep, free of charge. Enhance your home without any extra costs.",
        },
      },
    },
    ar: {
      _about: "معلومات عنا",
      _timings: "أوقات العمل",
      _locations: "عناوين الفروع",
      _gallery: "معرض المنتجات",
      _offers: "العروض و التخفيضات",
      _brandName: "تيماف للأقمشة والستائر",
      _addText1: "خصومات كبيرة",
      _addText2: "توصيل و تركيب مجاني",
      _addText3: "قياس مجاني",
      _addText4: "خصم خاص علي التفصيل",
      _curtainsCollection:
        "اكتشف مجموعة متنوعة من الستائر الفاخرة  تعكس أناقة منزلك",
      _curtainsOffers: "عروض الستائر",
      _saturday: "السبت",
      _sunday: "الأحد",
      _monday: "الاثنين",
      _closed: "مغلق",
      _workTime: " ٩:٠٠ صباحًا - ١١،٠٠ مساءً",
      _clickForMore: "اضغط هنا للمزيد",

      _locationsHeader:
        "قم بزيارة متاجرنا لاستكشاف مجموعة الستائر الخاصة بنا بنفسك",

      _aboutP1: `مرحبا بكم في متجر تيماف للستائر والأقمشة! مع أكثر من 21 عامًا من الخبرة في السوق ، أنشأنا أنفسنا كمزود رائد للستائر عالية الجودة. منذ عام 2002 ، كرسنا جهودنا لتقديم أفضل مواد النسيج ، وبأسعار تنافسية ، وخدمة استثنائية لما بعد البيع لعملائنا الكرام.`,
      _aboutP2: `في فروعنا الثلاثة للستائر والأقمشة الموجودة في مدينة نصر والمعادي والسادس من أكتوبر ، نفخر بمجموعة واسعة من الستائر التي تلبي مختلف الأنماط والتفضيلات. سواء كنت تبحث عن ستائر معتمة أو ستائر شفافة أو ستائر أطفال ، فلدينا الخيارات المثالية لتحسين أجواء منزلك.`,
      _aboutP3:
        "كجزء من التزامنا براحة العملاء ، نقدم قياسات مجانية لضمان الملاءمة المثالية للنوافذ الخاصة بك. نقدم أيضًا خدمات توصيل مجانية ، حيث نقدم ستائرك المختارة إلى عتبة داركم. علاوة على ذلك ، يقدم المتخصصون المهرة لدينا تثبيتًا مجانيًا ، مما يضمن تجربة خالية من المتاعب من البداية إلى النهاية.",

      _addresses: {
        branch1: {
          title: "فرع مدينة نصر",
          address: ".٤٣ شارع مصطفى النحاس ، مدينة نصر ، محافظة القاهرة ، مصر",
        },
        branch2: {
          title: "فرع المعادى",
          address: ".٣٠ شارع النصر ـ مدينة نصر ـ محافظة القاهرة ـ مصر",
        },
        branch3: {
          title: "فرع مدينة السادس من أكتوبر",
          address:
            ".شارع معزي للمحور المركزي ، السادس من أكتوبر ، محافظة الجيزة ، مصر",
        },
      },
      // Offers
      _offersList: {
        offer1: {
          title: "وفر ٣٠ ٪ من إجمالي الفاتورة",
          details:
            "لفترة محدودة ، استمتع بخصم سخي قدره ٣٠ ٪ من إجمالي فاتورتك. جمّل منزلك بالستائر الفاخرة مع توفير الكثير",
        },
        offer2: {
          title: "تركيب مجاني",
          details:
            "استفد من عرضنا واحصل على تركيب احترافي مجاني عند شراء الستارة. اجلس واسترخ بينما يتولى خبراؤنا التثبيت نيابة عنك",
        },
        offer3: {
          title: "استمتع بالتوصيل المجاني",
          details:
            "احصل على ستائرك مباشرة إلى عتبة داركم مجانًا. حسّن منزلك دون أي تكاليف إضافية",
        },
      },
    },
  },
});

// Aos is an animation library that animates elements on scroll
AOS.init();
new Vue({
  i18n,
  vuetify,
  router,
  mounted() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
