import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 1. Define route components.
// These can be imported from other files

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  {
    path: "/",
    component: () => import("@/views/layout/UnauthorizedLayout.vue"),
    children: [
      {
        path: "",
        name: "landingPage",
        component: () => import("@/views/landing/LandingPage.vue"),
      },
      {
        path: "survey/:surveyNumber",
        name: "orderSurvey",
        component: () => import("@/views/survey/OrderSurveyForm.vue"),
        props: true,
      },
    ],
  },

  { path: "*", redirect: { name: "landingPage" } },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes,
  // mode: 'history',
  base: "/",
});

export default router;
