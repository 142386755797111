<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>
<script>
export default {};
</script>
<style>
@import "../node_modules/sal.js/dist/sal.css";
</style>
